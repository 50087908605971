<template>
  <div class="induction">
    <div class="condition-box">
      <el-row style="padding: 0 30px">
        <el-col :span="21">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="100px"
          >
            <el-form-item>
              <!-- <el-input v-model="formInline.keyword" ></el-input> -->
              <el-input
                placeholder="请输入内容"
                prefix-icon="el-icon-search"
                @change="search"
                @keyup.enter.native="search"
                v-model="formInline.keyword"
                style="width: 220px"
              ></el-input>
            </el-form-item>
            <el-form-item label="全部职类：" style="display: none">
              <el-select
                v-model="formInline.skillId"
                @change="search"
                placeholder="请选择职类"
                style="width: 130px"
              >
                <el-option
                  v-for="item1 in tradeoptions"
                  :key="item1.value"
                  :label="item1.label"
                  :value="item1.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位状态：">
              <el-select
                v-model="formInline.status"
                @change="statusChange"
                placeholder="请选择状态"
                style="width: 130px"
              >
                <el-option
                  v-for="item2 in stutsoptions"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="3">
          <div class="" style="text-align: right">
            <el-button type="primary" @click="toadd()">
              <i class="el-icon-plus"></i>
              &nbsp;发布入职
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 数据列表 -->
    <div class="listbox" :style="{ 'min-height': sheight + 'px' }">
      <div class="list" v-for="(itemes, index1) in list" :key="index1">
        <el-row>
          <el-col :span="6">
            <div class="p-info">
              <div class="font22 p-tit">{{ itemes.jobName }}</div>
              <div class="font16">
                {{ itemes.city }} |
                {{
                  itemes.educationType.length
                    ? itemes.educationType[0].itemText
                    : "不限"
                }}
                | {{ itemes.expLow }}-{{ itemes.expHigh }}年 |
                {{ (itemes.salaryLow / 1000) | numFilter }}K-{{ (itemes.salaryHigh / 1000) | numFilter }}K
              </div>
              <div class="font14" style="font-weight: 450" v-if="itemes.companyUserJobName !== ''">
                  {{ "来自关联账号 " }} {{ itemes.companyUserJobName + " - " + itemes.companyUserName }}
              </div>
            </div>
          </el-col>
          <el-col :span="11">
            <div class="p-remarks font14" style="white-space: pre-wrap;">
              <div class="">{{ itemes.pushCount }}人查看</div>
              <div class="">{{ showCount(itemes.talkCount) }}</div>
              <div class="">{{ showDate(itemes.updateDate, itemes.createDate) }}</div>
<!--              <div class="">排序{{ itemes.orderNum }}</div>-->
            </div>
          </el-col>
          <el-col :span="7">
            <div class="p-operation">
              <div class="">
                <img
                  src="../../assets/images/position/edit.png"
                  @click="edit(2, itemes)"
                />
              </div>
              <div class="" v-if="itemes.status == 0">
                <img
                  src="../../assets/images/position/upin.png"
                  @click="edit(1, itemes)"
                />
              </div>
              <!-- 下架图片没切 -->
              <div class="" v-if="itemes.status == 1">
                <img
                  src="../../assets/images/position/upon.png"
                  @click="edit(0, itemes)"
                />
              </div>
              <div class="">
                <img
                  src="../../assets/images/position/delete.png"
                  @click="edit(-1, itemes)"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- <div class="font14 p-shu">涵涵 . 产品经理</div> -->
      </div>
    </div>
    <!-- 分页 -->
    <div class="paginate" style="position: relative">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="pageInfo.count"
      ></el-pagination>
    </div>

    <!-- 删除弹窗 -->
    <el-dialog
      :visible.sync="deleteDialogVisible"
      width="600px"
      center
      class="delete_box"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="db-img">
        <img src="../../assets/images/position/Dialog_delete.png" />
      </div>
      <div class="db-sure font22">确定删除当前职位?</div>
      <div class="db-ts font16">删除后不能恢复，是否确认删除</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delCommit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上下架弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="updownDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">
          {{ editConfig.status == 1 ? "上架成功" : "下架成功" }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      editConfig: {
        companyJobId: "",
        status: "",
      },
      sheight: "",
      formInline: {
        keyword: "",
        skillId: "",
        status: "",
        pageNum: 1,
        pageSize: 10,
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        count: 1,
        dtos: [],
      },
      // 全部职类
      tradeoptions: [{ value: "", label: "全部" }],
      // 全部状态
      stutsoptions: [
        { value: "3", label: "全部" },
        { value: "0", label: "下架中" },
        { value: "1", label: "上架中" },
      ],
      deleteDialogVisible: false,
      updownDialogVisible: false,
      isInfoPass: false,
    };
  },
  created() {
    // console.log("PAGE-induction ------ created!");
    this.sheight = document.documentElement.clientHeight - 380;
    this.fetchData(this.pageInfo.pageNum);
    // this.getList();
    this.getAllSkillList();
  },
  activated() {
    // console.log("PAGE-induction ------ activated!");
    this.$api.getUserInfo("get").then((res) => {
      if (res.code == 10200) {
        this.isInfoPass = res.data.company.isInfoPass;
      }
    });
    this.statusChange("3");
    // this.sheight = document.documentElement.clientHeight - 380;
    // this.fetchData(this.pageInfo.pageNum);
    // this.getList();
  },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    getList(status) {
      //获取列表
      let config = {
        status: status && status != 3 ? status : "",
        keyword: this.formInline.keyword,
      };
      this.$api.joblist("get", config).then((res) => {
        if(res.code === 10200){
          let list = [];
          res.data.map((item) => {
            if (item.jobType == 2 && item.status != -1) {
              list.push(item);
            }
          });
          this.list = list;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    getAllSkillList() {
      let skillList = [];
      let skills = JSON.parse(sessionStorage.getItem("skill"));
      // console.log("@AddPosition ----- @activated ---- sessionStorage.getItem = ", skills);

      if (skills) {
        if (skills.itSkills && skills.itSkills.length > 0){
          skills.itSkills.map((itSkill) =>{
            skillList.push(itSkill);
          });
        }
        if (skills.chipSkills && skills.chipSkills.length > 0){
          skills.chipSkills.map((chipSkill) =>{
            skillList.push(chipSkill);
          });
        }
      }

      // console.log("@recruitment ----- @created ---- this.skillList = ", skillList);
      if(skillList.length === 0) {
        // 通过api获取技能标签
        let skills = {
          itSkills: [],
          chipSkills: [],
        };
        let flag = false;
        this.$api.getSkillList("get", {code: "skill"}).then((res1) => {
          if (res1.data === null || res1.data.length === 0) {
            flag = true;
          }
          // console.log("----getSkill-----$api.getSkillList-----skill--- result = ", res1.data);
          skills.itSkills = res1.data;
          skills.itSkills.map((item) => {
            let obj = {
              label: item.name,
              value: item.id,
            };
            this.tradeoptions.push(obj);
          });
          this.$api.getSkillList("get", {code: "chip_skill"}).then((res2) => {
            if (res2.data === null || res2.data.length === 0) {
              flag = true;
            }
            // console.log("----getSkill-----$api.getSkillList-----chip_skill--- result = ", res2.data);
            skills.chipSkills = res2.data;
            skills.chipSkills.map((item) => {
              let obj = {
                label: item.name,
                value: item.id,
              };
              this.tradeoptions.push(obj);
            });
            if(flag) {
              this.$message({
                message: "获取技能列表失败！ "+ res2.message,
                type: "error",
              });
              return;
            }
            window.sessionStorage.setItem("skill", JSON.stringify(skills));
          });
        });
      } else {
        skillList.map((item) => {
          let obj = {
            label: item.name,
            value: item.id,
          };
          this.tradeoptions.push(obj);
        });
      }
    },
    statusChange(value) {
      //状态改变
      this.formInline.status = value;
      this.getList(value);
    },
    search() {
      let config = {
        status: this.formInline.status,
        keyword: this.formInline.keyword,
      };
      this.$api.joblist("get", config).then((res) => {
        let list = [];
        res.data.map((item) => {
          if (item.jobType == 2 && item.status != -1) {
            list.push(item);
          }
        });
        this.list = list;
      });
    },
    edit(status, data) {
      //编辑
      if (status == 2) {
        this.$router.push({ path: "/editposition", query: { id: data.id } });
      } else {
        this.editConfig.companyJobId = data.id;
        this.editConfig.status = status;
        if (status == -1) {
          //删除
          this.deleteDialogVisible = true;
        } else {
          //上下架
          this.$api.editjobtype("post", this.editConfig).then(() => {
            // console.log(res);
            this.updownDialogVisible = true;
            setTimeout(() => {
              this.updownDialogVisible = false;
              this.getList(this.formInline.stuts);
            }, 1000);
          });
        }
      }
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      this.formInline.pageNum = this.pageInfo.pageNum;
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    // 显示文本优化
    showCount(count) {
      if (count > 0) {
        return "已沟通过" + count + "人";
      }
      return "尚未发起沟通";
    },
    // 项目时间显示优化
    showDate(date, createDate){
      if(date === null){
        return "发布于\n" + createDate.split(" ")[0] + " " + H + ":" + m;
      }

      let H = date.split(" ")[1].split(":")[0];
      let m = date.split(" ")[1].split(":")[1];

      var fDate = new Date(date.split(" ")[0]);
      var dateTime = 1000*60*60*24; //每一天的毫秒数
      var minusDays = Math.floor(((new Date().getTime() - fDate.getTime())/dateTime));//计算出两个日期的天数差
      var days = Math.abs(minusDays);//取绝对值

      if(days === 1){
        return "上次更新 昨天 " + H + ":" + m;
      } else if(days < 1){
        return "上次更新 今天 " + H + ":" + m;
      } else {
        return "上次更新\n" + date.split(" ")[0] + " " + H + ":" + m;
      }
    },
    delCommit() {
      //删除确认
      this.$api.editjobtype("post", this.editConfig).then(() => {
        // console.log(res);
        this.deleteDialogVisible = false;
        this.getList(this.formInline.stuts);
      });
    },
    toadd() {
      if (!this.isInfoPass) {
        this.$alert("企业资料审核中", "发布失败", {
          confirmButtonText: "确定",
        });
        return;
      }
      this.$router.push("/addposition");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  border-radius: 0px;
}
.induction {
  .condition-box {
    background-color: #ffffff;
    padding-top: 22px;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
  }
}

.listbox {
  .list:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
  }
  .list {
    // padding: 0 30px;
    background-color: #ffffff;
    margin-top: 20px;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
    padding: 20px 30px;
    .p-shu {
      color: #666666;
      line-height: 2;
    }
    .p-info {
      color: #666666;
      line-height: 2;
      .p-tit {
        color: #111;
        font-weight: 600;
      }
    }
    .p-remarks {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 96px;
      div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        color: #666666;
      }
    }
    .p-operation {
      display: flex;
      height: 96px;
      div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        img:hover {
          cursor: pointer;
        }
        img {
          width: 58px;
          height: 58px;
        }
      }
    }
  }
}
.delete_box {
  line-height: 4;
  .db-img {
    text-align: center;
    img {
      width: 53px;
      height: 57px;
    }
  }
  .db-sure {
    text-align: center;
    color: #111111;
    line-height: 1.5;
  }
  .db-ts {
    text-align: center;
    color: #666666;
    line-height: 1.5;
  }
  .dialog-footer {
    margin-top: 20px;
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    button {
      width: 200px;
      height: 40px;
    }
  }
}

.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
}
</style>
